import React from 'react';
import Producto from './products';
import useProductos from '../hooks/useProductos'

const Collections = () => {
    const info=useProductos();
    let info1 = info.filter(producto => producto.producto.productobandera === "si");
    return (
        <section className="product-section" >
            <h1 style={{fontSize: "2rem", fontFamily: "Arial", fontWeight: "normal"}}>PRODUCTOS</h1>
            <div className="product-dispatch-carousel">
            {
               info1.map((producto,index)=>
                 (<Producto key={index} producto={producto}/>)
               )
             }
            </div>
        </section>
    );
};

export default Collections;