import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 2000,
    arrows:true
  };
  
const Carousel = () => {
  
    const { imag1,imag2,imag3,imag4} = useStaticQuery(graphql`
    query myqueryRedes {
      imag1: file(relativePath: { eq: "bg1.jpg" }) {
        sharp: childImageSharp {
          fluid (maxWidth: 1920, maxHeight: 580) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag2: file(relativePath: { eq: "bg2.jpg" }) {
        sharp: childImageSharp {
          fluid (maxWidth: 1920, maxHeight: 580) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag3: file(relativePath: { eq: "bg5.jpg" }) {
        sharp: childImageSharp {
          fluid (maxWidth: 1920, maxHeight: 580) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag4: file(relativePath: { eq: "bg6.jpg" }) {
        sharp: childImageSharp {
          fluid (maxWidth: 1920, maxHeight: 580) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
    }
  `)

  const image1 = [imag1.sharp.fluid]
  const image2 = [imag2.sharp.fluid]
  const image3 = [imag3.sharp.fluid]
  const image4 = [imag4.sharp.fluid]
  const imagenes=[image4,image2,image3,image1]
    return (
        <Slider {...settings} >
          {
            imagenes.map((value,index)=> {
              
             return <Img fluid={value} key={index}/>
            })
          }
        
  
      </Slider>
    );
};

export default Carousel;