import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from '../components/Header/newheader'
import Collections from "../components/Collections";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import Domotica from "../components/Sections/Domotica";
import Asistentes from "../components/Asistentes";
import CarouselMarcas from "../components/CarouselMarcas";


const IndexPage = () => {

  return (
    <Layout>
      <Header/>
      <Carousel/>
      <Seo title="Madtronix" description="Venta" siteUrl="https://madtronix-peru.com"  />  
      <Collections />
      <Domotica />
      <Asistentes/>
      <CarouselMarcas/>
    <Footer/>
    </Layout>
  )
}

export default IndexPage
