import React from 'react';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
//import useAsisentes from '../hooks/useAsistentes';

const Asistententes = () => {
    const { image } = useStaticQuery(graphql`
    query myqueryAsistente {
      image: file(relativePath: { eq: "googleAssistant.jpeg" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 1030, maxHeight: 539) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
     
    }
  `)

    const DomoImage = [image.sharp.fluid]
    //const info=useAsisentes();
    return (
        <div className="asistentes">
            <h4 >Y ahora, toma el control domótico también por voz</h4>
            <h5 >Nuestros sistemas domóticos son compatibles con los principales asistentes de voz</h5>
          
             <div className="asistente-img">
             <Img loading="lazy"  className="asistente-img" style={{ maxWidth: "60rem", maxHeight: "30rem", backgroundColor: "white", borderRadius: "25px" }} fluid={DomoImage} alt="asistente" />
                </div>
            </div>
    );
};

export default Asistententes;