import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings_3 = {
    
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,  
    autoplaySpeed: 3000,
    arrows:true,
    responsive: [{
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },{
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    }]
  };
  
const CarouselMarcas = () => {
  
    const { imag1,imag2,imag3,imag4,imag5,imag6} = useStaticQuery(graphql`
    query myqueryMarcas {
      imag1: file(relativePath: { eq: "google-logo.jpeg" }) {
        sharp: childImageSharp {
            fluid(maxWidth: 140, maxHeight: 80) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag2: file(relativePath: { eq: "tuya-logo.jpeg" }) {
        sharp: childImageSharp {
            fluid(maxWidth: 140, maxHeight: 80)  {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag3: file(relativePath: { eq: "Alexa-Logo.jpeg" }) {
        sharp: childImageSharp {
            fluid(maxWidth: 140, maxHeight: 80) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag4: file(relativePath: { eq: "apple-logo.jpeg" }) {
        sharp: childImageSharp {
            fluid(maxWidth: 140, maxHeight: 80) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag5: file(relativePath: { eq: "smartlife-logo.png" }) {
        sharp: childImageSharp {
            fluid(maxWidth: 240, maxHeight: 104) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      imag6: file(relativePath: { eq: "tplink-logo.jpeg" }) {
        sharp: childImageSharp {
            fluid(maxWidth: 140, maxHeight: 80) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
    }
  `)

  const image1 = [imag1.sharp.fluid]
  const image2 = [imag2.sharp.fluid]
  const image3 = [imag3.sharp.fluid]
  const image4 = [imag4.sharp.fluid]
  const image5 = [imag5.sharp.fluid]
  const image6 = [imag6.sharp.fluid]
  const imagenes=[image1,image2,image3,image4,image5,image6]
    return (
        <div className="slick-marcas">
            <h1 style={{textAlign:"center", marginTop:"3rem"}}>Trabajamos con las Marcas</h1>
        <Slider {...settings_3} >
          
          {
            imagenes.map((value,index)=> {
              
             return <div  key={index}><h3><Img style={{width:"140px",height:"80px", objectFit:"contain"}} fluid={value} key={index}/></h3></div>
            })
          }
      
  
      </Slider>
      </div>
    );
};

export default CarouselMarcas;