import React from 'react';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Domotica = () => {
  const { image, image1 } = useStaticQuery(graphql`
    query myqueryDomotica {
      image: file(relativePath: { eq: "casaiot.jpeg" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 600, maxHeight: 400) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
      image1: file(relativePath: { eq: "nosotros.webp" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 600, maxHeight: 400) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
     
    }
  `)

  const DomoImage = [image.sharp.fluid]
  const casaImage = [image1.sharp.fluid]
  return (
    <section>
      <div className="domotica">
        <div className="domotica-box">
          <div className="domotica-box-2">
            <div className="inner">

           
            <h6>Sobre domótica</h6>
            <h2 style={{ fontSize: "3rem", textAlign: "left", marginBottom: "0" }}>¿Qué es la Domótica?</h2>
            <br />
            <p style={{ lineHeight: "2", fontSize: "1rem" }}> Se entiende por Domótica el conjunto de sistemas capaces de automatizar una vivienda, aportando servicios de gestión energética, seguridad, bienestar y comunicación, y que pueden estar integrados por medio de redes interiores y exteriores de comunicación, cableadas o inalámbricas, y cuyo control goza de cierta ubicuidad, desde dentro y fuera del hogar. Se podría definir como la integración de la tecnología en el diseño inteligente de un recinto cerrado.
                                            La domótica aplicada a grandes edificios es también denominada como Inmótica.</p>
                                            </div>
         </div>
        </div>
        <div className="domotica-box">
          <div className="domotica-image-1">
            <Img loading="lazy" style={{ maxHeight: "25rem", backgroundColor: "white", borderRadius: "25px" }} fluid={DomoImage} alt="domotica1" />
          </div>

        </div>
      </div>
      <div className="domotica">
        <div className="domotica-box">
          <div className="domotica-image-2">
            <Img loading="lazy" style={{  maxHeight: "25rem", backgroundColor: "white", borderRadius: "25px" }} fluid={casaImage} alt="domotica2" />
          </div>
        </div>
        <div className="domotica-box">
          <div className="domotica-box-4">
            <h6>VIVE LA EXPERIENCIA SMART CON</h6>
            <h2 style={{ fontSize: "3rem", textAlign: "left", marginBottom: "0" }}>Espacios Inteligentes</h2>
            <br />
            <p style={{ lineHeight: "2", fontSize: "1rem", marginBottom: "1rem" }}> Combina la automatización de cortinas, luces, televisores, cerraduras, música y mucho más en un solo botón, creando espacios únicos que pueden ser personalizados por ti.</p>
            <a href="https://api.whatsapp.com/send?phone=51928285568&app=facebook&entry_point=page_cta&fbclid=IwAR2Q1yfyWRPWTG46oU1js-A_RRXjNim9aDAvfN3oKORsN_vS1KV_Q54trS8"
              target="_blank"
              rel="noreferrer"
              className="card__servicios-boton">Contactanos por Whatsapp</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Domotica;