import React from 'react';
import Image from "gatsby-image"
import {  Link } from "gatsby"

const Producto = ({producto}) => {
    var backgroundFluidImageStack = "";

    const { imagen,titulo ,subtitulo,slug} = producto.producto;
    if (imagen) {
      backgroundFluidImageStack = imagen.fluid
    } else {
    }
    return (
        <div  className="product-item pnogrey nohave"  >
        
          {" "}
          <Link to={`productos/${slug}`} className="btn btn-primary"> <Image imgStyle={{objectPosition:"right"}}
            className="product-image"
            fluid={backgroundFluidImageStack}
          />
       </Link>
  
        <div style={{flex:"1 1 auto", minHeight:"1px",padding:"1.25rem",marginBottom:"2rem", justifyContent:"center"}}>
        <h3 className="product-title"> {titulo}</h3>
         <h2 className="product-title">{subtitulo}</h2>
         
        </div>
    
      </div>
    );
};

export default Producto;